<script setup lang="ts">
    import { computed, onMounted, ref } from 'vue'
    import { useDokumentStore } from '@/stores'
    import { type Dokument } from '@/js/model'
    import { formatDate } from '@/utils'

    const props = defineProps<{
        dokumentId: Dokument['dokumentId']
    }>()

    const dokumentStore = useDokumentStore()
    const overlay = ref<boolean>(false)

    onMounted(async () => {
        await dokumentStore.fetchDokumentById(props.dokumentId)
    })

    const dokument = computed<Dokument | undefined>(() => {
        return dokumentStore.getDokumentById(props.dokumentId) ?? undefined
    })
    const isImage = computed<boolean>(() => {
        if (!dokument.value) {
            return false
        }
        return ['jpg', 'jpeg', 'png', 'bmp'].includes(
            dokument.value.dokument.extension.toLowerCase()
        )
    })
    const isPdf = computed<boolean>(() => {
        if (!dokument.value) {
            return false
        }
        return 'pdf' === dokument.value.dokument.extension.toLowerCase()
    })
</script>

<template>
    <v-card v-if="dokument" :loading="!dokument">
        <v-img
            v-if="isImage"
            :src="dokument.src"
            max-width="200"
            min-width="200"
            class="mt-4 ml-4 cursor-pointer"
            :title="dokument.dokument.filename"
            @click="overlay = !overlay"
        />
        <v-overlay
            v-if="isImage"
            v-model="overlay"
            content-class="d-flex align-center justify-center w-100 h-100"
        >
            <v-card max-width="95%" max-height="95%">
                <v-card-title>{{ dokument.dokument.filename }}</v-card-title>
                <v-card-text class="d-flex justify-center pt-4 border">
                    <vue-image-zoomer
                        hover-message="Drüberfahren um zu zoomen"
                        :title="dokument.dokument.filename"
                        :regular="dokument.src"
                        img-class="border"
                    />
                </v-card-text>
                <v-card-actions>
                    <v-btn variant="tonal" color="primary" block @click="overlay = false"
                        >Schließen</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-overlay>
        <v-icon v-else-if="isPdf" :icon="`mdi-file-pdf-box`" size="200" />
        <v-card-item>
            <v-card-title>{{ (dokument.dokument.dokumentTyp as any)?.typ }}</v-card-title>

            <v-card-subtitle :title="dokument.dokument.filename">
                <v-icon v-if="isPdf" :icon="`mdi-file-pdf-box`"></v-icon>
                {{ dokument.dokument.filename }}
            </v-card-subtitle>
            <v-card-subtitle>
                {{ formatDate(dokument.dokument.dokumentDate) }}
            </v-card-subtitle>
            <v-card-actions>
                <v-btn
                    variant="elevated"
                    prepend-icon="mdi-download"
                    target="_blank"
                    :href="dokument.src"
                    >Download</v-btn
                >
            </v-card-actions>
        </v-card-item>
    </v-card>
</template>
<style scoped></style>
