<script setup lang="ts">
    import {
        type Betriebspruefung,
        type ChangeErgebnisOfBetriebspruefungRequest,
        type MangelDerPruefung
    } from '@/js/model'
    import { useBetriebspruefungService } from '@/services'
    import { useAlertStore } from '@/stores'
    import { computed, onMounted, ref } from 'vue'
    import { DokumentPreviewCell, UploadErgebnisDerPruefungPicturesCell } from '@/components'

    const props = defineProps<{
        betriebspruefung: Betriebspruefung
    }>()

    const emit = defineEmits<{
        itemChanged: [event: Betriebspruefung]
    }>()

    const betriebspruefungService = useBetriebspruefungService()
    const alertStore = useAlertStore()

    const showDialog = ref<boolean>(false)
    const selectedMangel = ref<MangelDerPruefung | undefined>(undefined)
    const mangelBeschreibung = ref<string>('')

    const mangelItems = ref([
        {
            mangel: '<nicht erfasst>',
            mangelKey: 0
        },
        {
            mangel: 'kein – Betriebsprüfung bestanden',
            mangelKey: 1
        },
        {
            mangel: 'geringfügig – Betriebsprüfung bestanden',
            mangelKey: 2
        },
        {
            mangel: 'mittel – Betriebsprüfung durchgefallen',
            mangelKey: 3
        },
        {
            mangel: 'erheblich – Betriebsprüfung durchgefallen',
            mangelKey: 4
        }
    ])

    const mangelLabel = computed(() => {
        switch (props.betriebspruefung.pruefungsergebnis.mangel?.mangelKey) {
            case 0:
                return '<nicht erfasst>'
            case 1:
                return 'kein Mangel'
            case 2:
                return 'geringfügiger Mangel'
            case 3:
                return 'mittlerer Mangel'
            case 4:
                return 'erheblicher Mangel'
            default:
                return ''
        }
    })

    const bgClass = computed(() => {
        if (props.betriebspruefung.isAbgeschlossen) {
            return 'white'
        }

        switch (props.betriebspruefung.pruefungsergebnis.mangel?.mangelKey) {
            case 0:
                return 'grey'
            case 1:
                return 'green'
            case 2:
            case 3:
                return 'yellow'
            case 4:
                return 'red'
            default:
                return ''
        }
    })

    function setInitialValues() {
        selectedMangel.value = JSON.parse(
            JSON.stringify(props.betriebspruefung.pruefungsergebnis.mangel)
        )
        mangelBeschreibung.value = props.betriebspruefung.pruefungsergebnis.bemerkung
    }

    onMounted(async () => {
        setInitialValues()
    })

    const onItemChanged = (result: Betriebspruefung) => {
        emit('itemChanged', result)
    }

    const handleErgebnisChanged = async () => {
        const request: ChangeErgebnisOfBetriebspruefungRequest = {
            betriebspruefung: props.betriebspruefung['@id'],
            mangelDerPruefung: selectedMangel.value,
            bemerkung: mangelBeschreibung.value
        }
        const result = await betriebspruefungService.changePruefungsergebnis(request)
        if (result) {
            emit('itemChanged', result)
            showDialog.value = false
            return
        }

        const message = 'Beim Speichern des Prüfungsergebnisses ist etwas schiefgelaufen.'
        alertStore.error(message)
        throw new Error(message)
    }

    const handleDismiss = async () => {
        showDialog.value = false
        setInitialValues()
    }
</script>

<template>
    <v-chip v-if="betriebspruefung.isAbgeschlossen && selectedMangel" color="primary" label>
        {{ mangelLabel }}
    </v-chip>
    <v-btn
        v-else-if="selectedMangel"
        append-icon="mdi-pencil"
        variant="elevated"
        :color="bgClass"
        size="small"
        class="my-1"
        block
        @click="showDialog = true"
    >
        {{ mangelLabel }}
    </v-btn>

    <v-dialog v-model="showDialog" max-width="1800" @click:outside="handleDismiss">
        <v-card prepend-icon="mdi-file-outline" title="Ergebnis der Betriebsprüfung ändern">
            <template #text>
                <v-select
                    label="Grad des Mangels"
                    v-model="selectedMangel"
                    :items="mangelItems"
                    item-title="mangel"
                    item-value="mangelKey"
                    return-object
                    variant="solo-filled"
                />
                <v-textarea
                    v-model="mangelBeschreibung"
                    label="Beschreibung des Mangels"
                    hint="Welche Mängel sollte der Fachbetrieb beheben."
                    rows="8"
                    no-resize
                    variant="solo-filled"
                />
                <v-alert type="info" color="grey"
                    >Die Beschreibung wird u.a. dem Fachbetrieb in einer E-Mail mitgeteilt.</v-alert
                >
            </template>

            <v-divider></v-divider>
            <v-card-subtitle>Dokumente zur Betriebsprüfung</v-card-subtitle>
            <v-container fluid>
                <v-row>
                    <v-col v-for="dokumentId in betriebspruefung.dokumentIds" :key="dokumentId">
                        <DokumentPreviewCell
                            :dokument-id="dokumentId"
                            :key="dokumentId"
                            class="grid"
                        />
                    </v-col>
                    <v-col v-if="!betriebspruefung.dokumentIds.length">
                        Noch keine Dokumente hinzugefügt.
                    </v-col>
                </v-row>
            </v-container>

            <UploadErgebnisDerPruefungPicturesCell
                :betriebspruefung="betriebspruefung"
                @item-changed="onItemChanged"
            />
            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text="Abbrechen" variant="plain" @click="handleDismiss"></v-btn>
                <v-btn
                    color="primary"
                    text="Speichern"
                    variant="tonal"
                    @click="handleErgebnisChanged"
                ></v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<style scoped></style>
