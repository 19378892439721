<script setup lang="ts">
    import dayjs from 'dayjs'
    import {
        type Betriebspruefung,
        type Fachbetrieb,
        type FuegeBetriebspruefungsdokumentHinzuRequest
    } from '@/js/model'
    import { useBetriebspruefungService, useFachbetriebService } from '@/services'
    import { useAlertStore } from '@/stores'
    import { onMounted, ref } from 'vue'
    import FilePreview from '@/components/FilePreview.vue'
    import { formatDate } from '@/utils'

    const props = defineProps<{
        betriebspruefung: Betriebspruefung
    }>()

    const emit = defineEmits<{
        itemChanged: [event: Betriebspruefung]
    }>()

    const betriebspruefungService = useBetriebspruefungService()
    const fachbetriebService = useFachbetriebService()
    const alertStore = useAlertStore()

    const fileDate = ref<Date | null>(null)
    const showDialog = ref<boolean>(false)
    const mainCategory = ref<string>('')
    const urkundeCategory = ref<string>('')
    const nachweiseCategory = ref<string>('')
    const additionalFilenamePart = ref<string>('')

    const images = ref<File[]>([])

    const mainCategoryItems = ref([
        {
            value: 'urkunden',
            content: 'Prüfberichte/Urkunden'
        },
        {
            value: 'nachweise',
            content: 'Nachweise Betriebsbeauftragter'
        },
        {
            value: 'sonstiges',
            content: 'Schriftverkehr/Anlagen zum Prüfbericht/Sonstiges'
        }
    ])
    const urkundeCategoryItems = ref([
        {
            value: 'urkunden',
            content: 'Urkunden'
        },
        {
            value: 'pruefberichte',
            content: 'Prüfberichte'
        }
    ])
    const nachweiseCategoryItems = ref([
        {
            value: 'meisterurkunde',
            content: 'Meisterurkunde'
        },
        {
            value: 'teilnahmebestätigung',
            content: 'Teilnahmebestätigung'
        }
    ])

    const fachbetrieb = ref<Fachbetrieb | null>(null)

    onMounted(async () => {
        fileDate.value = new Date()
        console.log(props.betriebspruefung.fachbetriebId)
        fachbetrieb.value = await fachbetriebService.getFachbetriebById(
            props.betriebspruefung.fachbetriebId
        )
    })

    function setInitialValues() {
        images.value = []
        additionalFilenamePart.value = ''
    }

    onMounted(async () => {
        setInitialValues()
    })

    const removeImage = (index: number) => {
        images.value.splice(index, 1)
    }

    const readFile = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()

            reader.onload = (res) => {
                resolve(res.target?.result as string)
            }
            reader.onerror = (err) => reject(err)

            reader.readAsDataURL(file)
        })
    }

    const readFiles = async (): Promise<string[]> => {
        const promises: Promise<string>[] = []
        images.value.forEach((file) => {
            promises.push(readFile(file))
        })
        return await Promise.all(promises)
    }

    const handleFormSubmit = async () => {
        const request: FuegeBetriebspruefungsdokumentHinzuRequest = {
            betriebspruefung: props.betriebspruefung['@id'],
            fileDataList: await readFiles(),
            dokumentTyp: { typ: 'Urkunde' },
            additionalFilenamePart: additionalFilenamePart.value,
            dokumentDate: dayjs(fileDate.value).toISOString()
        }
        const result = await betriebspruefungService.fuegeBetriebspruefungsdokumentHinzu(request)
        if (result) {
            emit('itemChanged', result)
            showDialog.value = false
            setInitialValues()
            return
        }

        const message = 'Beim Speichern des Prüfungsergebnisses ist etwas schiefgelaufen.'
        alertStore.error(message)
        throw new Error(message)
    }

    const handleDismiss = async () => {
        showDialog.value = false
        setInitialValues()
    }
</script>

<template>
    <v-dialog v-model="showDialog" fullscreen scrollable transition="dialog-bottom-transition">
        <template v-slot:activator="{ props: activatorProps }">
            <v-btn
                prepend-icon="mdi-clipboard-file-outline"
                size="small"
                :text="`${betriebspruefung.dokumentIds.length ? 'weitere ' : ''}Prüfungsdokumente hochladen`"
                v-bind="activatorProps"
            ></v-btn>
        </template>

        <v-card>
            <v-toolbar>
                <v-btn icon="mdi-close" @click="handleDismiss"></v-btn>

                <v-toolbar-title>Dokumente der Betriebsprüfung hochladen</v-toolbar-title>

                <v-spacer></v-spacer>

                <v-toolbar-items>
                    <v-btn text="Speichern" variant="text" @click="handleFormSubmit"></v-btn>
                </v-toolbar-items>
            </v-toolbar>

            <v-card style="overflow: initial; z-index: initial" color="grey-lighten-1">
                <v-card-title>Kategorisierung</v-card-title>
                <v-card-subtitle>Dateityp wählen</v-card-subtitle>
                <v-card-text>
                    <v-col cols="12">
                        <v-btn-toggle
                            v-model="mainCategory"
                            color="primary"
                            group
                            density="compact"
                        >
                            <v-btn
                                v-for="mainCategoryItem in mainCategoryItems"
                                :value="mainCategoryItem.value"
                                :key="mainCategoryItem.value"
                                >{{ mainCategoryItem.content }}</v-btn
                            >
                        </v-btn-toggle>
                    </v-col>
                    <v-col v-if="'urkunden' === mainCategory" cols="12">
                        <v-btn-toggle
                            v-model="urkundeCategory"
                            color="primary"
                            group
                            density="compact"
                        >
                            <v-btn
                                v-for="categoryItem in urkundeCategoryItems"
                                :value="categoryItem.value"
                                :key="categoryItem.value"
                                >{{ categoryItem.content }}</v-btn
                            >
                        </v-btn-toggle>
                    </v-col>
                    <v-col v-if="'nachweise' === mainCategory" cols="12">
                        <v-btn-toggle
                            v-model="nachweiseCategory"
                            color="primary"
                            group
                            density="compact"
                        >
                            <v-btn
                                v-for="categoryItem in nachweiseCategoryItems"
                                :value="categoryItem.value"
                                :key="categoryItem.value"
                                >{{ categoryItem.content }}</v-btn
                            >
                        </v-btn-toggle>
                    </v-col>
                </v-card-text>
                <v-card-subtitle>Datum der Datei</v-card-subtitle>
                <v-card-text>
                    <VueDatePicker
                        v-if="fileDate"
                        v-model="fileDate"
                        :week-numbers="{ type: 'iso' }"
                        placeholder="Datum der Datei (wird für Dateinamen verwendet)"
                        :clearable="false"
                        required
                        time-picker-inline
                        locale="de"
                        :enable-time-picker="false"
                        format="dd.LL.y"
                        cancel-text="abbrechen"
                        select-text="auswählen"
                        :teleport="true"
                        six-weeks="center"
                        :action-row="{ showPreview: false }"
                    />
                </v-card-text>
                <v-card-subtitle>Eigenen Dokumentnamensteil vergeben</v-card-subtitle>
                <v-card-text>
                    <v-text-field
                        v-model="additionalFilenamePart"
                        type="text"
                        bg-color="white"
                        density="compact"
                        placeholder="zusätzlicher Teil des Dokumentnamens"
                        label="Dokumentnamensteil (optional)"
                        name="additionalFilenamePart"
                        help="Wird im Dateinamen vor dem Datum eingefügt."
                        clearable
                        persistent-clear
                    />
                </v-card-text>
                <v-card-subtitle>Dateiname</v-card-subtitle>
                <v-card-text v-if="fachbetrieb">
                    <pre class="pa-2 bg-white font-weight-bold"
                        >{{ fachbetrieb.firma.nameOne }},{{ fachbetrieb.firma.nameTwo }},{{
                            fachbetrieb.mitgliedsnummer
                        }}{{ mainCategory ? '_' + mainCategory : ''
                        }}{{
                            nachweiseCategory
                                ? '_' + nachweiseCategory
                                : urkundeCategory
                                  ? '_' + urkundeCategory
                                  : ''
                        }}{{ additionalFilenamePart ? '_' + additionalFilenamePart : '' }}_{{
                            formatDate(fileDate)
                        }}{{ '.pdf' }}</pre
                    >
                </v-card-text>
            </v-card>
            <v-card style="overflow: initial; z-index: initial" color="grey-lighten-2">
                <v-card-title>Fotos hochladen</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col>
                            <v-file-input
                                v-model="images"
                                accept="image/*,.pdf"
                                label="Bilder"
                                counter
                                multiple
                                show-size
                                hint="Bilder werden zu einer PDF-Datei zusammengefügt."
                                persistent-hint
                                placeholder="Bilder hochladen"
                                prepend-icon="mdi-camera"
                            ></v-file-input>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            v-for="(image, key) in images"
                            class="d-flex child-flex"
                            :key="key"
                            cols="12"
                            sm="4"
                            md="4"
                            lg="3"
                        >
                            <v-badge color="success" :content="key" location="left top">
                                <v-card class="d-flex flex-column">
                                    <FilePreview v-if="image" :file="image" />
                                </v-card>
                                <v-avatar
                                    inline
                                    color="primary"
                                    size="24"
                                    class="ml-n3 mt-n3"
                                    @click="removeImage(key)"
                                >
                                    <v-icon small dark>mdi-close</v-icon>
                                </v-avatar>
                            </v-badge>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-card>
    </v-dialog>
</template>

<style scoped></style>
