<script lang="ts" setup>
    import {
        ActiveCell,
        AdresseCell,
        BooleanCell,
        KontaktCell,
        LandesstelleFilter,
        type LandesstelleFilterEvent
    } from '@/components'
    import type { Fachbetrieb, Fachpruefer } from '@/js/model'
    import { useFachbetriebService } from '@/services'
    import {
        useAuthStore,
        useBetriebsbeauftragterStore,
        useFachbetriebStore,
        useFachprueferStore,
        useLandesstellenStore
    } from '@/stores'
    import {
        type FetchParams,
        formatDate,
        formatDateTime,
        itemsPerPageOptions,
        itemsPerPageText,
        loadingText,
        noItemsText,
        type SortItem,
        type TableHeaders
    } from '@/utils'
    import { computed, onBeforeMount, ref, type Ref, watch } from 'vue'

    const authStore = useAuthStore()

    const itemsPerPage = ref<number>(25)
    const allHeaders: TableHeaders = [
        { title: 'Mitgliedsnummer', align: 'start', key: 'mitgliedsnummer' },
        { title: 'Firma', align: 'start', key: 'firma.name' },
        { title: 'Adresse', align: 'start', key: 'adresse', sortable: false },
        { title: 'Kontakt', align: 'start', key: 'kontakt', sortable: false },
        { title: 'SBE-Kundennummer', align: 'start', key: 'sbeKundennummer' },
        { title: 'Landesstelle', align: 'start', key: 'landesstelleId' },
        { title: 'Fachprüfer', align: 'start', key: 'fachprueferId' },
        {
            title: 'Betriebsbeauftragte',
            align: 'start',
            key: 'betriebsbeauftragterIds',
            sortable: false
        },
        {
            title: 'Zertifizierungen',
            align: 'start',
            key: 'zertifizierungIds',
            sortable: false
        },
        { title: 'laufende Nummer', align: 'start', key: 'laufendeNummer' },
        { title: 'ID in Access', align: 'start', key: 'fachbetriebAccessId' },
        { title: 'Mitgliedsstatus', align: 'start', key: 'isMitglied' },
        { title: 'NÜF', align: 'start', key: 'isNichtueberwachtesMitglied' },
        { title: 'mM', align: 'start', key: 'isMittlererMangel' },
        {
            title: 'ÜWG-Aufnahme am',
            align: 'start',
            key: 'aufnahmeAt',
            value: (item) => `${formatDate(item.aufnahmeAt)}`
        },
        {
            title: 'WV am',
            align: 'start',
            key: 'wiedervorlageAt',
            value: (item) => `${formatDate(item.wiedervorlageAt)}`
        },
        {
            title: 'gerätetechn. Ausstattung',
            align: 'start',
            key: 'hasGeraetetechnischeAusstattung'
        },
        { title: 'Handbuch Gewässerkunde', align: 'start', key: 'hasHandbuchGewaesserkunde' },
        { title: 'mM', align: 'start', key: 'isMittlererMangel' },
        {
            title: 'Handwerksrolle seit',
            align: 'start',
            key: 'handwerksrolleEingetragenAt',
            value: (item) => `${formatDate(item.handwerksrolleEingetragenAt)}`
        },
        {
            title: 'Handwerksrolle',
            align: 'start',
            key: 'handwerksrolleEintragung'
        },
        {
            title: 'Kammerbezirk',
            align: 'start',
            key: 'kammerbezirk'
        },
        {
            title: 'Innungsmitglied',
            align: 'start',
            key: 'isInnungsmitglied'
        },
        {
            title: 'Innung',
            align: 'start',
            key: 'innung'
        },
        {
            title: 'Überwachungsbereich Stillegen',
            align: 'start',
            key: 'isUeberwachungsbereichStilllegen'
        },
        {
            title: 'Überwachungsbereich Erdwärme',
            align: 'start',
            key: 'isUeberwachungsbereichErdwaerme'
        },
        {
            title: 'Freigabe der Urkunde am',
            align: 'start',
            key: 'letzteRegelueberwachungAt',
            value: (item) => `${formatDate(item.letzteRegelueberwachungAt)}`
        },
        { title: 'Urkunde zurück', align: 'start', key: 'urkundeZurueck' },
        { title: 'Bemerkungen ÜWG', align: 'start', key: 'bemerkungenHauptstelle' },
        { title: 'Bemerkungen LS', align: 'start', key: 'bemerkungenLandesstelle' },
        { title: 'Änderungen', align: 'start', key: 'alteDatenAenderung' },
        {
            title: 'mm keine Urkunde nur Rechnung',
            align: 'start',
            key: 'mmKeineUrkundeNurRechnung'
        },
        {
            title: 'Prüfung vor Ort am',
            align: 'start',
            key: 'pruefungVorOrtAt',
            value: (item) => `${formatDate(item.pruefungVorOrtAt)}`
        },
        {
            title: 'Letzte Änderung',
            align: 'start',
            key: 'updatedAt',
            value: (item) => `${formatDateTime(item.updatedAt)}`
        }
    ]

    const isFachpruefer = computed(() => {
        return authStore.hasRole('Fachpruefer')
    })

    const headers = ref<TableHeaders>(
        authStore.hasRole('Uewg')
            ? allHeaders
            : allHeaders.filter(
                  (header) =>
                      undefined !== header.key &&
                      ![
                          'isMittlererMangel',
                          'fachprueferId',
                          'sbeKundennummer',
                          'zertifizierungIds',
                          'laufendeNummer',
                          'fachbetriebAccessId',
                          'mmKeineUrkundeNurRechnung',
                          'alteDatenAenderung',
                          'hasGeraetetechnischeAusstattung',
                          'hasHandbuchGewaesserkunde',
                          'bemerkungenHauptstelle',
                          'bemerkungenLandesstelle'
                      ].includes(header.key)
              )
    )

    const serverItems: Ref<Fachbetrieb[]> = ref([])
    const tableOptions = ref<{ sortBy: SortItem[]; page?: number; itemsPerPage?: number }>({
        sortBy: []
    })
    const loading = ref<boolean>(true)
    const totalItems = ref<number>(0)
    const mitgliedsnummer = ref<string | null>(null)
    const isMitglied = ref<boolean | null>(null)
    const isNichtueberwachtesMitglied = ref<boolean | null>(null)
    const isMittlererMangel = ref<boolean | null>(null)
    const hasNoZertifizierungen = ref<boolean | null>(null)
    const search = ref<string>('')
    const landesstelleFilter = ref<LandesstelleFilterEvent>({ all: true, selection: [] })
    const fachprueferFilter = ref<string[]>([])
    const selectAllFachpruefer = ref(true)

    const betriebsbeauftragterStore = useBetriebsbeauftragterStore()
    const fachbetriebStore = useFachbetriebStore()
    const fachbetriebService = useFachbetriebService()
    const landesstellenStore = useLandesstellenStore()
    const fachprueferStore = useFachprueferStore()

    const loadItems = async ({
        page,
        itemsPerPage,
        sortBy
    }: FetchParams & {
        sortBy: SortItem[]
    }) => {
        loading.value = true
        tableOptions.value = {
            page,
            itemsPerPage,
            sortBy
        }
        let data: FetchParams = {
            page,
            itemsPerPage,
            mitgliedsnummer: mitgliedsnummer.value,
            isMitglied: isMitglied.value,
            isNichtueberwachtesMitglied: isNichtueberwachtesMitglied.value,
            isMittlererMangel: isMittlererMangel.value,
            zertifizierungIds: hasNoZertifizierungen.value ? '[]' : null,
            ...getFachprueferFilter(fachprueferFilter.value),
            ...getLandesstellenFilter()
        }

        if (sortBy[0]) {
            sortBy.forEach((val: SortItem) => (data[`order[${val.key}]`] = val.order))
        }

        try {
            const result = await fachbetriebService.getAllFachbetriebe(data)
            const items = result?.['hydra:member'] || [{}]
            const total = result?.['hydra:totalItems'] || 0
            for (const fachbetrieb of items) {
                for (const betriebsbeauftragterId of fachbetrieb?.betriebsbeauftragterIds || []) {
                    betriebsbeauftragterStore.fetchBetriebsbeauftragterById(betriebsbeauftragterId)
                }
            }

            serverItems.value = items
            totalItems.value = total ?? 0
            loading.value = false
        } catch (e: unknown) {
            loading.value = false
        }
    }

    onBeforeMount(async () => {
        await Promise.all([fachprueferStore.fetchFachpruefende()])
        const promises: Promise<void>[] = []
        fachprueferStore.fachpruefende.forEach((fachpruefer: Fachpruefer) => {
            promises.push(
                fachbetriebStore.fetchAnzahlFachbetriebeByFachprueferId(fachpruefer.fachprueferId)
            )
        })
        await Promise.all(promises)
    })

    watch(
        [
            mitgliedsnummer,
            isMitglied,
            isNichtueberwachtesMitglied,
            isMittlererMangel,
            hasNoZertifizierungen
        ],
        () => {
            search.value = String(Date.now())
        }
    )

    const onFilterFachpruefer = async (newFilter: string[]) => {
        if (newFilter.length > 0) {
            selectAllFachpruefer.value = false
        }
        await loadItems({ ...tableOptions.value })
    }

    const fachprueferFilterOptions = computed(() => {
        return fachprueferStore.fachpruefende.map((item) => {
            return {
                title: `${item.fullName} - ${fachbetriebStore.getAnzahlFachbetriebeByFachprueferId(item.fachprueferId)} Fachbetriebe`,
                value: item.fachprueferId
            }
        })
    })

    const getFachprueferFilter = (newFilter: string[]) => {
        if (selectAllFachpruefer.value) {
            return { fachprueferId: null }
        } else {
            return { fachprueferId: newFilter }
        }
    }
    const getLandesstellenFilter = () => {
        if (landesstelleFilter.value.all) {
            return { landesstelleId: null }
        } else {
            return { landesstelleId: landesstelleFilter.value.selection }
        }
    }

    const toggleSelectAllFachpruefer = async () => {
        if (selectAllFachpruefer.value) {
            selectAllFachpruefer.value = false
            await onFilterFachpruefer(fachprueferFilter.value)
        } else {
            selectAllFachpruefer.value = true
            fachprueferFilter.value = []
            await onFilterFachpruefer([])
        }
    }
</script>

<template>
    <v-row class="pb-4">
        <v-col cols="12" md="4" lg="4" xl="3">
            <v-text-field
                v-model="mitgliedsnummer"
                density="compact"
                placeholder="Nach Mitgliedsnummer suchen..."
                append-inner-icon="mdi-magnify"
                hide-details
                clearable
            ></v-text-field>
        </v-col>
        <v-col cols="12" md="4" lg="4" xl="3">
            <LandesstelleFilter
                @selectionChanged="
                    async (newFilter) => {
                        landesstelleFilter = newFilter
                        await loadItems({ ...tableOptions })
                    }
                "
            />
        </v-col>
        <v-col v-if="!isFachpruefer" cols="12" md="4" lg="4" xl="3">
            <v-select
                density="compact"
                v-model="fachprueferFilter"
                :items="fachprueferFilterOptions"
                label="Nach Fachprüfer filtern..."
                multiple
                hide-details
                @update:modelValue="onFilterFachpruefer"
            >
                <template v-slot:prepend-item>
                    <v-list-item title="Alle auswählen" @click="toggleSelectAllFachpruefer">
                        <template v-slot:prepend>
                            <v-checkbox-btn :model-value="selectAllFachpruefer"></v-checkbox-btn>
                        </template>
                    </v-list-item>

                    <v-divider class="mt-2"></v-divider>
                </template>
            </v-select>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="3" xl="2">
            <v-btn-toggle v-model="isMitglied" color="primary" group density="compact">
                <v-btn value="1">Mitglied</v-btn>
                <v-btn value="0">Kein Mitglied</v-btn>
            </v-btn-toggle>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="2" xl="2">
            <v-btn-toggle
                v-model="isNichtueberwachtesMitglied"
                color="primary"
                group
                density="compact"
                title="nicht überwachtes Mitglied (NÜF)"
            >
                <v-btn value="1">NÜF</v-btn>
                <v-btn value="0">Kein NÜF</v-btn>
            </v-btn-toggle>
        </v-col>
        <v-col v-if="!isFachpruefer" cols="12" sm="6" md="4" lg="2" xl="2">
            <v-btn-toggle
                v-model="isMittlererMangel"
                color="primary"
                group
                density="compact"
                title="mittlerer Mangel (mM)"
            >
                <v-btn value="1">mM</v-btn>
                <v-btn value="0">Kein mM</v-btn>
            </v-btn-toggle>
        </v-col>
        <v-col v-if="!isFachpruefer" cols="12" sm="6" md="4" lg="3" xl="2">
            <v-btn-toggle v-model="hasNoZertifizierungen" color="primary" group density="compact">
                <v-btn value="0">Keine Zertifizierungen</v-btn>
            </v-btn-toggle>
        </v-col>
    </v-row>

    <v-data-table-server
        v-model:items-per-page="itemsPerPage"
        :headers="headers"
        :items="serverItems"
        :items-length="totalItems"
        :items-per-page-options="itemsPerPageOptions"
        :items-per-page-text="itemsPerPageText"
        :loading="loading"
        :search="search"
        item-value="name"
        :no-data-text="noItemsText"
        :loading-text="loadingText"
        multi-sort
        density="compact"
        @update:options="loadItems"
    >
        <template v-slot:header.letzteRegelueberwachungAt="{ column }">
            <span
                title="Beurkundung ist rausgeschickt am. In Access ist dies die Spalte letzteRegelueberwachungAm//LRUDatum."
                >{{ column.title }}</span
            >
        </template>
        <template v-slot:item.isNichtueberwachtesMitglied="{ item }">
            <BooleanCell
                data-test="is-nichtueberwachtes-mitglied"
                :is-enabled="item.isNichtueberwachtesMitglied"
            />
        </template>
        <template v-slot:item.hasGeraetetechnischeAusstattung="{ item }">
            <BooleanCell :is-enabled="item.hasGeraetetechnischeAusstattung" />
        </template>
        <template v-slot:item.hasHandbuchGewaesserkunde="{ item }">
            <BooleanCell :is-enabled="item.hasHandbuchGewaesserkunde" />
        </template>
        <template v-slot:item.isInnungsmitglied="{ item }">
            <BooleanCell :is-enabled="item.isInnungsmitglied" />
        </template>
        <template v-slot:item.isUeberwachungsbereichStilllegen="{ item }">
            <BooleanCell :is-enabled="item.isUeberwachungsbereichStilllegen" />
        </template>
        <template v-slot:item.isUeberwachungsbereichErdwaerme="{ item }">
            <BooleanCell :is-enabled="item.isUeberwachungsbereichErdwaerme" />
        </template>
        <template v-slot:item.isMittlererMangel="{ item }">
            <BooleanCell data-test="is-mittlerer-mangel" :is-enabled="item.isMittlererMangel" />
        </template>
        <template v-slot:item.isMitglied="{ item }">
            <ActiveCell :is-enabled="item.isMitglied ?? false" />
            <div class="mt-1 text-caption">
                <span v-if="item.aufnahmeAt">
                    Aufnahme&nbsp;am&nbsp;{{ formatDate(item.aufnahmeAt) }}
                </span>
                <span v-if="item.austritt">
                    Austritt: {{ item.austritt.austrittsgrund }}
                    {{
                        item.austritt.austrittAt ? `am ${formatDate(item.austritt.austrittAt)}` : ''
                    }}
                </span>
                <span v-if="item.wiedereintrittAt">
                    Wiedereintritt: {{ formatDate(item.wiedereintrittAt) }}
                </span>
            </div>
        </template>
        <template v-slot:item.bemerkungenHauptstelle="{ item }">
            <span class="preformatted-text">{{ item.bemerkungenHauptstelle }}</span>
        </template>
        <template v-slot:item.bemerkungenLandesstelle="{ item }">
            <span class="preformatted-text">{{ item.bemerkungenLandesstelle }}</span>
        </template>
        <template v-slot:item.kontakt="{ item }">
            <KontaktCell :kontakt="item.kontakt" />
        </template>
        <template v-slot:item.adresse="{ item }">
            <AdresseCell :adresse="item.adresse" />
        </template>
        <template v-slot:item.landesstelleId="{ item }">
            {{ landesstellenStore.getNameById(item.landesstelleId) }}
        </template>
        <template v-slot:item.fachprueferId="{ item }">
            {{ fachprueferStore.getFullNameById(item.fachprueferId) }}
        </template>
        <template v-slot:item.betriebsbeauftragterIds="{ item }">
            <div
                v-for="betriebsbeauftragterId in item.betriebsbeauftragterIds"
                :key="betriebsbeauftragterId"
            >
                {{
                    betriebsbeauftragterStore.getBetriebsbeauftragterById(betriebsbeauftragterId)
                        ?.vorname
                }}
                {{
                    betriebsbeauftragterStore.getBetriebsbeauftragterById(betriebsbeauftragterId)
                        ?.nachname
                }}
            </div>
        </template>
        <template v-slot:item.zertifizierungIds="{ item }">
            <v-chip>
                {{ item.zertifizierungIds.length }}
            </v-chip>
        </template>
    </v-data-table-server>
</template>

<style scoped>
    :deep(.v-select .v-field-label) {
        color: grey;
        opacity: 1;
    }

    :deep(.v-input input::placeholder) {
        color: grey;
        opacity: 1;
    }
</style>
