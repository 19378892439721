<script lang="ts" setup>
    import { ref, type Ref } from 'vue'
    import { useImportedFileService } from '@/services'
    import { type ImportedFile } from '@/js/model'
    import {
        type FetchParams,
        formatDateTime,
        itemsPerPageOptions,
        itemsPerPageText,
        loadingText,
        noItemsText,
        type SortItem,
        type TableHeaders
    } from '@/utils'
    import { BooleanCell } from '@/components'
    import { useDebounceFn } from '@vueuse/core'

    const itemsPerPage = ref<number>(25)
    const headers = ref<TableHeaders>([
        { title: 'laufende Nummer', align: 'start', key: 'importedFileId' },
        { title: 'Typ', align: 'start', key: 'type' },
        { title: 'Dateiname', align: 'start', key: 'filename' },
        { title: 'Erfolgreich importiert?', align: 'start', key: 'isImportedSuccessfully' },
        {
            title: 'Importiert am',
            align: 'start',
            key: 'importedAt',
            value: (item) => `${formatDateTime(item.importedAt)}`
        }
    ])

    const serverItems: Ref<ImportedFile[]> = ref([])
    const tableOptions = ref<{ sortBy: SortItem[]; page?: number; itemsPerPage?: number }>({
        sortBy: []
    })
    const loading = ref<boolean>(true)
    const totalItems = ref<number>(0)

    const importedFileService = useImportedFileService()

    let abortController: AbortController | null = null

    const loadItems = async ({
        page,
        itemsPerPage,
        sortBy
    }: FetchParams & {
        sortBy: SortItem[]
    }) => {
        if (abortController) {
            abortController.abort()
        }

        abortController = new AbortController()
        const signal = abortController.signal

        loading.value = true
        tableOptions.value = {
            page,
            itemsPerPage,
            sortBy
        }
        let data: FetchParams = {
            page,
            itemsPerPage
        }

        if (sortBy[0]) {
            sortBy.forEach((val: SortItem) => (data[`order[${val.key}]`] = val.order))
        }
        try {
            const result = await importedFileService.getAllImportedFiles(data, { signal })
            const items = result['hydra:member']
            const total = result['hydra:totalItems']

            serverItems.value = items
            totalItems.value = total ?? 0
            loading.value = false
        } catch (e: unknown) {
            loading.value = false
        }
    }

    const debouncedLoadItems = useDebounceFn(loadItems, 300)
</script>

<template>
    <v-row class="pb-4">
        <v-col cols="12" md="3"> </v-col>
    </v-row>
    <v-data-table-server
        v-model:items-per-page="itemsPerPage"
        :headers="headers"
        :items="serverItems"
        :items-length="totalItems"
        :items-per-page-options="itemsPerPageOptions"
        :items-per-page-text="itemsPerPageText"
        :loading="loading"
        item-value="name"
        :no-data-text="noItemsText"
        :loading-text="loadingText"
        multi-sort
        density="compact"
        @update:options="debouncedLoadItems"
    >
        <template v-slot:item.type="{ item }">
            <v-chip
                density="compact"
                variant="flat"
                size="small"
                :color="
                    item.type === 'MIT'
                        ? 'purple'
                        : item.type === 'Schul' || item.type === 'Schulung'
                          ? 'deep-orange'
                          : 'brown'
                "
            >
                {{ item.type }}
            </v-chip>
        </template>
        <template v-slot:item.isImportedSuccessfully="{ item }">
            <BooleanCell :is-enabled="item.isImportedSuccessfully" />
        </template>
    </v-data-table-server>
</template>

<style scoped>
    :deep(.v-select .v-field-label) {
        color: grey;
        opacity: 1;
    }

    :deep(.v-input input::placeholder) {
        color: grey;
        opacity: 1;
    }
</style>
