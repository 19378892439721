<script lang="ts" setup>
    import { ActiveCell, GenderCell } from '@/components'
    import type { User } from '@/js/model'
    import { useUserService } from '@/services'
    import { useAuthStore, useFachprueferStore, useLandesstellenStore } from '@/stores'
    import {
        type FetchParams,
        itemsPerPageOptions,
        itemsPerPageText,
        loadingText,
        noItemsText,
        type SortItem,
        type TableHeaders
    } from '@/utils'
    import { onBeforeMount, type Ref, ref, watch } from 'vue'

    const itemsPerPage = ref<number>(25)
    const serverItems: Ref<User[]> = ref([])
    const loading = ref<boolean>(true)
    const totalItems = ref<number>(0)
    const name = ref<string | null>(null)
    const search = ref<string>('')

    const userService = useUserService()
    const fachprueferStore = useFachprueferStore()
    const landesstellenStore = useLandesstellenStore()
    const authStore = useAuthStore()

    const adminHeaders = ref<Exclude<TableHeaders, undefined>>([
        { title: 'Aktionen', key: 'actions', sortable: false }
    ])
    const headers = ref<TableHeaders>([
        { title: 'Titel', align: 'start', key: 'titel', sortable: false },
        { title: 'Vorname', align: 'start', key: 'vorname', sortable: false },
        { title: 'Nachname', align: 'start', key: 'nachname', sortable: false },
        { title: 'E-Mail', align: 'start', key: 'email.email' },
        { title: 'Geschlecht', align: 'start', key: 'gender', sortable: false },
        // { title: 'Account aktiviert?', align: 'start', key: 'isEnabled' },
        { title: 'Rollen', align: 'start', key: 'rollen', sortable: false },
        ...(authStore.hasRole('Uewg') || authStore.isUserSwitched ? adminHeaders.value : [])
    ])

    const loadItems = async ({
        page,
        itemsPerPage,
        sortBy
    }: {
        page: number
        itemsPerPage: number
        sortBy: SortItem[]
    }) => {
        loading.value = true
        let data: FetchParams = {
            page,
            itemsPerPage,
            'email.email': name.value
        }

        if (sortBy[0]) {
            sortBy.forEach((val: SortItem) => (data[`order[${val.key}]`] = val.order))
        }

        try {
            const result = await userService.getAllUsers(data)
            const items = result['hydra:member']
            const total = result['hydra:totalItems']

            serverItems.value = items
            totalItems.value = total ?? 0
            loading.value = false
        } catch (e: unknown) {
            loading.value = false
        }
    }

    onBeforeMount(async () => {
        await Promise.all([fachprueferStore.fetchFachpruefende()])
    })

    watch([name], () => {
        search.value = String(Date.now())
    })
</script>

<template>
    <v-row class="pb-4">
        <v-col cols="12" md="4">
            <v-text-field
                v-model="name"
                density="compact"
                placeholder="Nach E-Mail suchen..."
                append-inner-icon="mdi-magnify"
                hide-details
                clearable
            ></v-text-field>
        </v-col>
    </v-row>
    <v-data-table-server
        v-model:items-per-page="itemsPerPage"
        :headers="headers"
        :items="serverItems"
        :items-length="totalItems"
        :items-per-page-options="itemsPerPageOptions"
        :items-per-page-text="itemsPerPageText"
        :loading="loading"
        :search="search"
        item-value="name"
        :no-data-text="noItemsText"
        :loading-text="loadingText"
        density="compact"
        @update:options="loadItems"
    >
        <template v-slot:item.email.email="{ item }">{{ item.email?.email }}</template>
        <template v-slot:item.isEnabled="{ item }">
            <ActiveCell :is-enabled="item.isEnabled ?? false" />
        </template>
        <template v-slot:item.rollen="{ item }">
            <template v-for="(rolle, key) in item.rollen?.rollen" :key="key">
                <div>
                    {{ rolle.rollenName?.rollenName }}
                </div>
                <template v-if="rolle.fachprueferId">
                    <div>
                        {{ fachprueferStore.getLandesstelleByFachprueferId(rolle.fachprueferId) }}
                    </div>
                </template>
                <template v-else-if="rolle.landesstelleId">
                    <div>
                        {{ landesstellenStore.getNameById(rolle.landesstelleId) }}
                    </div>
                </template>
            </template>
        </template>
        <template v-slot:item.gender="{ item }">
            <GenderCell :gender="item.gender" />
        </template>
        <template v-slot:item.actions="{ item }">
            <v-btn
                v-if="authStore.isUserSwitched"
                color="secondary"
                size="x-small"
                variant="flat"
                prepend-icon="mdi-account-switch"
                data-test="action-end-user-change"
                @click="authStore.exitUserSwitch()"
                >Nutzerwechsel beenden</v-btn
            >
            <v-btn
                v-else
                color="secondary"
                size="x-small"
                variant="flat"
                prepend-icon="mdi-account-switch"
                @click="authStore.switchUser(item)"
                data-test="action-user-change"
                >Nutzer wechseln</v-btn
            >
        </template>
    </v-data-table-server>
</template>
