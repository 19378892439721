<script setup lang="ts">
    import { ref, watch } from 'vue'
    const props = defineProps<{
        file: File
    }>()

    const preview = ref<string | null>(null)
    watch(
        () => props.file,
        (newFile) => {
            if (newFile) {
                const reader = new FileReader()
                reader.readAsDataURL(newFile)
                reader.onload = () => {
                    preview.value = reader.result as string
                }
            } else {
                preview.value = null
            }
        },
        { immediate: true }
    )
</script>

<template>
    <v-container>
        <v-img v-if="preview" :src="preview" max-width="300" min-width="200" class="mt-4"></v-img>
    </v-container>
</template>
<style scoped></style>
